import Toggler from '@marcomessa/toggler';
require('slick-carousel');
/*import Glide from '@glidejs/glide';*/

const toggler = new Toggler;

import $ from 'jquery';

window.$ = window.jQuery = $;

/* slider `tela`i */
/*new Glide('.glide').mount()*/

$(document).ready( function (){
    $('.main-carousel').slick({
        dots: false,
        arrows: false,
        slidesToShow: 1
    });

    $('.carousel').slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ]
    });
});



/* According color-page.htm */
var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        if (panel.classList.contains("block")) {
            panel.classList.remove("block");
            panel.classList.add("hidden");
        } else {
            panel.classList.add("block");
            panel.classList.remove("hidden");
        }
    });
}

/* tab company.htm */
let tabs = document.getElementsByClassName('tablinks');

for (var i = 0; i < tabs.length; i++) {

    tabs[i].addEventListener('click', function(evt) {
        let tabName = this.dataset.tab;

        // Get all elements with class="tabcontent" and hide them
        let tabcontent = document.getElementsByClassName("tabcontent");

        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Get all elements with class="tablinks" and remove the class "active"
        let tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += " active";

    })
}

/* scroll company.htm */
$(document).ready(function(){
    // Add smooth scrolling to all links
    $("a").on('click', function(event) {

        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 500, function(){

                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
            });
        } // End if
    });
});
